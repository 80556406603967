<template>
  <div class="services">
    <div class="wrapper">
      <CBreadcrumbs :current-page-name="pageHeader" />
    </div>
    <section class="tabs">
      <h1 class="tabs__title">{{ pageHeader }}</h1>
      <div class="tabs__wrapper">
        <div class="tabs__header wrapper">
          <div
            class="tabs__btn"
            :class="{ active: openTab === 'adult' }"
            @click="changeOpenTab('adult')"
          >
            <span>Для взрослых</span>
          </div>
          <div
            class="tabs__btn"
            :class="{ active: openTab === 'child' }"
            @click="changeOpenTab('child')"
          >
            <span>Для детей</span>
          </div>
        </div>
        <div class="tabs__content">
          <div v-if="openTab === 'adult'" class="tabs__content-item wrapper">
            <CServricesCard
              v-for="(item, index) in categorizeItems.adultList"
              :key="`services_adult_${index}`"
              :title="item.title"
              :children="item.children"
              :icon="item.icon"
              :slug="item.full_slug"
            />
          </div>
          <div v-if="openTab === 'child'" class="tabs__content-item wrapper">
            <CServricesCard
              v-for="(item, index) in categorizeItems.childList"
              :key="`services_adult_${index}`"
              :title="item.title"
              :children="item.children"
              :icon="item.icon"
              :slug="item.full_slug"
            />
          </div>
        </div>
      </div>
    </section>
    <COfficeBlock title="Адреса клиник" :offices="transformOffices" />
  </div>
</template>

<script setup lang="ts">
import './styles.scss';
import CBreadcrumbs from '~/components/breadcrumbs/CBreadcrumbs.vue';
import {
  computed,
  ref,
  useAsyncData,
  useHead,
  useRoute,
  useRouter,
  useRuntimeConfig,
  useSeoMeta,
  useState,
} from '#imports';
import type { ApiServicesResponse, ServicesResults } from '~/types/pages';
import CServricesCard from '~/components/sectionBlocks/servicesCard/CServricesCard.vue';
import COfficeBlock from '~/components/blocks/officeBlock/COfficeBlock.vue';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';

const servicesList = ref<ApiServicesResponse['results']>([]);
const pageHeader = ref<string>();
const offices = useState('affiliates');
const transformOffices = ref([]);
const { siteUrl } = useRuntimeConfig().public;
const route = useRouter().currentRoute.value;

const { data, error } = await useAsyncData<ErrorResponse | ApiServicesResponse>(
  'getServicesListResponse',
  () => {
    return $fetch(`/api/services/list`, {
      method: 'GET',
    });
  },
);

if (data.value) {
  if (!isErrorResponse(data.value)) {
    servicesList.value = data.value.results;
    pageHeader.value = data.value.seo.h1;

    useHead({
      title: data.value.title || data.value.seo.title,
    });

    useSeoMeta({
      description: data.value.seo.description.replace(/[""'`']/g, ''),
      keywords: data.value.seo.keywords.replace(/[""'`']/g, ''),
      ogTitle: data.value.og.title.replace(/[""'`']/g, ''),
      ogDescription: data.value.og.description.replace(/[""'`']/g, ''),
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });
  }
}

if (offices.value) {
  transformOffices.value = offices.value.map(el => ({
    title: el.title,
    workTime: el.working_time,
    address: el.address,
    phone: el.phone,
    lat: el.latitude,
    lon: el.longitude,
    image: el.image,
    rating: el.blocks[0],
  }));
}

const categorizeItems = computed(() => {
  const adultList: ServicesResults[] = [];
  const childList: ServicesResults[] = [];

  servicesList.value.forEach(item => {
    if (item.age === 'adult') {
      adultList.push(item);
    } else if (item.age === 'children') {
      childList.push(item);
    }
  });

  return {
    adultList,
    childList,
  };
});

const openTab = ref('adult');

const changeOpenTab = (tabName: string) => (openTab.value = tabName);
</script>

<style scoped></style>
