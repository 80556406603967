<template>
  <section class="tabs" :class="invertTabs ? 'invert' : ''">
    <div class="wrapper">
      <h2 class="tabs__title">{{ title }}</h2>
      <div class="tabs__wrapper">
        <div class="tabs__header">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="tabs__btn"
            :class="{ active: activeTab === index }"
            @click="setActive(index)"
          >
            <span>
              <NuxtIcon v-if="!isArticle && item.icon" class="icon" :name="tabsIcon[item.icon]" filled />
              {{ item.name }}
            </span>
          </div>
        </div>
        <div
          v-for="(item, index) in tabs"
          v-show="activeTab === index"
          :key="index"
          class="tabs__content"
        >
          <div class="tabs__content-item">
            <template v-if="item.type === 'text'">
              <CImageTextBlock
                :title="item.title"
                :image="item.image"
                :image-seo="item.image_seo"
                :button-text="item.button_text"
                :text="item.text"
                :button-trigger="item.button_trigger"
                :image-is-rounded="false"
              />
            </template>
            <template v-if="item.type === 'doctors'">
              <div v-if="item.doctors" class="specialists-slider wrapper">
                <h2>{{ item.title }}</h2>
                <ClientOnly>
                  <div class="swiper-container">
                    <Swiper
                    :modules="[Navigation, Pagination]"
                    navigation
                    :pagination="{
                      clickable: true
                    }"
                    :slides-per-view="1"
                    :space-between="16"
                    create-elements
                    :auto-height="false"
                    :breakpoints="breakpoints"
                    class="specialists-slider__slider"
                  >
                    <SwiperSlide v-for="(doctor, dIndex) in item.doctors" :key="dIndex">
                      <CSpesialistCard
                        :id="doctor.id"
                        :experience_from="doctor.experience_from"
                        :full_name="doctor.full_name"
                        :full_slug="doctor.full_slug"
                        :image="doctor.image"
                        :image_seo="doctor.image_seo"
                        :link="doctor.link"
                        :preview_text="doctor.description"
                      />
                    </SwiperSlide>
                  </Swiper>
                  </div>

                </ClientOnly>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { BlockTabs } from '~/types/pages';
import { ref } from '#imports';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import CImageTextBlock from '~/components/blocks/imageTextBlock/CImageTextBlock.vue';
import CSpesialistCard from '~/components/blocks/specialistCard/CSpesialistCard.vue';

interface Props {
  title: string;
  tabs: BlockTabs['media'];
  invertTabs?: boolean;
  isArticle?: boolean;
}

defineProps<Props>();

const activeTab = ref(0);

enum tabsIcon {
  'clinic' = 'IconClinic',
  'children' = 'IconChildren',
  'doctors' = 'IconDoctors',
  'in-dream' = 'IconInDream',
}

const breakpoints = {
  1025: {
    slidesPerView: 3,
    spaceBetween: 32,
    centeredSlides: false,
  },
  769: {
    slidesPerView: 3,
    spaceBetween: 24,
    centeredSlides: false,
  },
  576: {
    slidesPerView: 2,
    spaceBetween: 16,
    centeredSlides: false,
  },

};

const setActive = (tab: number) => (activeTab.value = tab);
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
