<template>
  <main class="specialists">
    <div class="wrapper">
      <CBreadcrumbs :current-page-name="pageHeader" />
    </div>
    <div class="specialists__tabs">
      <div class="specialists__tabs-header wrapper">
        <div
          class="specialists__tabs-btn"
          :class="{ active: !$router.currentRoute.value.query.affiliate }"
          @click="setFilters('affiliate', null)"
        >
          Все филиалы
        </div>
        <div
          v-for="(affiliate, affiliateIndex) in affiliates"
          :key="`affiliate_${affiliateIndex}`"
          class="specialists__tabs-btn"
          :class="{ active: $router.currentRoute.value.query.affiliate === affiliate.title }"
          @click="setFilters('affiliate', affiliate.title)"
        >
          <NuxtIcon class="icon" name="IconMetro"></NuxtIcon>
          {{ affiliate.title }}
        </div>
      </div>
      <div class="specialists__tabs-wrapper">
        <div class="wrapper">
          <nav class="menu">
            <ul class="menu__list">
              <li class="menu__item">
                <div
                  class="menu__link"
                  :class="{ active: !$router.currentRoute.value.query.profession_id }"
                  @click="setFilters('profession_id', null)"
                >
                  Все специалисты
                </div>
              </li>
              <li
                v-for="(prof, indexProf) in professions"
                :key="`prof_${indexProf}`"
                class="menu__item"
                @click="setFilters('profession_id', prof.id)"
              >
                <div
                  class="menu__link"
                  :class="{
                    active: $router.currentRoute.value.query.profession_id === String(prof.id),
                  }"
                >
                  {{ prof.title }}
                </div>
              </li>
            </ul>
          </nav>

          <div class="specialists__content">
            <h1 class="specialists__title">{{ pageHeader }}</h1>
            <UISelect
              v-model="modelValueSelect"
              class="field"
              placeholder="Специальности"
              :options="optionsProfessions"
              @update:model-value="setFilters('profession_id', modelValueSelect)"
            />

            <div class="specialists__list">
              <template v-if="doctors.length">
                <div v-if="leadingDoctors.length > 0" class="specialists__list-leading">
                  <h2 class="specialists__list-title">Ведущие врачи</h2>

                  <div class="specialists__list-wrapper">
                    <CSpesialistCard
                      v-for="(item, index) in leadingDoctors"
                      :id="item.id"
                      :key="`${index}_${item.full_slug}`"
                      :image="item.image"
                      :full_slug="item.full_slug"
                      :full_name="item.title"
                      :preview_text="item.description"
                      :image_seo="item.image_seo"
                      :link="item.link"
                      :experience_from="item.experience"
                    />
                  </div>
                </div>
                <div class="specialists__list-wrapper">
                  <CSpesialistCard
                    v-for="(item, index) in regularDoctors"
                    :id="item.id"
                    :key="`${index}_${item.full_slug}`"
                    :image="item.image"
                    :full_slug="item.full_slug"
                    :full_name="item.title"
                    :preview_text="formatCategories(item.category?.title, item.extra_categories)"
                    :image_seo="item.image_seo"
                    :link="item.link"
                    :experience_from="item.experience"
                  />
                </div>
              </template>
              <template v-else>
                <h2 class="specialists__list-title">
                  Врачи с такой специальностью принимают в нашем другом филиале
                </h2>
              </template>
            </div>

            <CPagination
              v-if="data?.count > doctors?.length"
              :pagination="{
                page: Number(currentPage),
                totalPages: totalPages,
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <COfficeBlock title="Адреса клиник" :offices="transformOffices" />
  </main>
</template>

<script lang="ts" setup>
import {
  computed,
  ref,
  watch,
  useRoute,
  useState,
  useRouter,
  onMounted,
  useAsyncData,
  inject,
  createError,
  useHead,
  useSeoMeta,
  useRuntimeConfig,
} from '#imports';
import './styles.scss';
import { type ApiPageResponse, type BlockOffice, type DoctorsCard } from '~/types/pages';
import CSpesialistCard from '~/components/blocks/specialistCard/CSpesialistCard.vue';
import CPagination from '~/components/sectionBlocks/pagination/CPagination.vue';
import CBreadcrumbs from '~/components/breadcrumbs/CBreadcrumbs.vue';
import COfficeBlock from '~/components/blocks/officeBlock/COfficeBlock.vue';
import type { LocationQueryValue } from 'vue-router';
import type { ProfessionsItem, SiteSetting } from '~/types/config';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';

const route = useRouter().currentRoute.value;
const router = useRouter();

const currentPage = computed(() => route.query?.page || 1);
const pageCount = inject<SiteSetting>('pageCount');

const { data, error } = await useAsyncData<ErrorResponse | ApiPageResponse>(
  'getDoctorsListResponse',
  () => {
    return $fetch(`/api/doctors/list`, {
      method: 'GET',
      params: {
        page_size: pageCount?.value || 9,
        ...route.query,
      },
    });
  },
  {
    watch: [currentPage],
  },
);

const { siteUrl } = useRuntimeConfig().public;

if (error.value) {
  throw createError(error.value);
}

const pageHeader = ref<string>();

const doctors = ref<DoctorsCard[]>([]);

if (data.value) {
  if (!isErrorResponse(data.value)) {
    pageHeader.value = data.value.seo?.h1 || data.value.seo?.title;
    doctors.value = data.value.results;

    useHead({
      title: data.value.title || data.value.seo.title,
    });

    useSeoMeta({
      description: data.value.seo.description.replace(/[""'`']/g, ''),
      keywords: data.value.seo.keywords.replace(/[""'`']/g, ''),
      ogTitle: data.value.og.title.replace(/[""'`']/g, ''),
      ogDescription: data.value.og.description.replace(/[""'`']/g, ''),
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });
  }
}

const affiliates = useState<BlockOffice['offices']>('affiliates');
const professions = useState('professions');
const transformOffices = ref([]);

const leadingDoctors = ref<DoctorsCard[]>([]);
const regularDoctors = ref<DoctorsCard[]>([]);

const totalPages = computed(() => Math.ceil(data.value.count / 9));

if (affiliates.value) {
  transformOffices.value = affiliates.value.map(el => ({
    title: el.title,
    workTime: el.working_time,
    address: el.address,
    phone: el.phone,
    lat: el.latitude,
    lon: el.longitude,
    image: el.image,
    rating: el.blocks[0],
  }));
}

const setFilters = (key: string, value: LocationQueryValue) => {
  const queryParams = { ...route.query };

  if (queryParams.page) {
    Reflect.deleteProperty(queryParams, 'page');
  }

  if (key === 'profession_id') {
    modelValueSelect.value = value;
  }

  if (!value && queryParams[key]) {
    Reflect.deleteProperty(queryParams, key);
  } else {
    queryParams[key] = value;
  }

  void router.replace({ query: queryParams });
};

function formatCategories(categoryName: string, extraCategories: ProfessionsItem[]) {
  if (extraCategories && extraCategories.length > 0) {
    const titles = extraCategories.map(category => category.title);

    if (!categoryName) {
      return `${titles.join(',')}`;
    }

    if (titles.length) {
      return `${categoryName} - ${titles.join(', ')}`;
    }
  }

  return `${categoryName}`;
}

const optionsProfessions = computed(() => {
  const items = professions.value?.map(el => ({
    value: el.id,
    title: el.title,
  }));

  return [
    {
      value: null,
      title: 'Все специалисты',
    },
    ...items,
  ];
});

const modelValueSelect = ref(null);

watch(
  () => doctors.value,
  newVal => {
    if (Array.isArray(newVal) && newVal.length > 0) {
      newVal.forEach(el => {
        if (el.primary_doctor) {
          leadingDoctors.value.push(el);
        } else {
          regularDoctors.value.push(el);
        }
      });
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

onMounted(() => {
  if (route.query.profession_id) {
    modelValueSelect.value = Number(route.query.profession_id);
  }
});
</script>
