<template>
  <teleport to="body">
    <transition name="fade" mode="in-out">
      <div v-if="modelValue" class="modal-overlay" @click="onCloseModal">
        <div class="modal-container" :class="styledClass" @click.stop>
          <div class="modal-header">
            <p v-if="title" class="modal-title">{{ title }}</p>
            <span class="modal-close" @click="onCloseModal"><NuxtIcon name="IconCancel" /></span>
          </div>

          <div class="modal-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import './styles.scss';
import { useRouter, watch } from '#imports';

interface Props {
  modelValue: boolean;
  styledClass?: string;
  title?: string;
}

const router = useRouter();

const props = defineProps<Props>();
const emit = defineEmits(['onCloseModal', 'update:modelValue']);

const onCloseModal = () => {
  emit('update:modelValue', !props.modelValue);
  emit('onCloseModal');
};
const handleKeyPress = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    if (props.modelValue) {
      onCloseModal();
    }
  }
};

const html = ref<HTMLElement>();

onMounted(() => {
  html.value = document.documentElement;
  document.addEventListener('keydown', handleKeyPress);
});

watch(
  () => props.modelValue,
  newVal => {
    if (newVal) {
      html.value?.classList.add('overflow');
    } else {
      html.value?.classList.remove('overflow');
    }
  },
);

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyPress);
});

// todo найти решение поделикатней
router.beforeEach(() => {
  emit('update:modelValue', false);
  emit('onCloseModal');
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
