<template>
  <main class="main-page">
    <h1 class="hidden-header">{{ pageHeader }}</h1>
    <template v-if="mainBannerBlock.length > 0">
      <CBannerMainPage
        v-if="mainBannerBlock"
        :title="mainBannerBlock[0].name"
        :image="mainBannerBlock[0].image"
        :image-seo="mainBannerBlock[0].image_seo"
        :button-text="mainBannerBlock[0].button_text"
        :button-phone="{
          text: mainBannerBlock[0].button_text_phone,
          phone: mainBannerBlock[0].phone,
        }"
        :text="mainBannerBlock[0].text"
        :button-trigger="mainBannerBlock[0].button_trigger"
      />
    </template>
    <template v-if="mainTabsBlock.length > 0">
      <CBlockTabs :title="mainTabsBlock[0].name" :tabs="mainTabsBlock[0].media" invert-tabs />
    </template>
    <template v-if="mainServicesBlock.length > 0">
      <CServiceCatalogCategoryBlock
        :title="mainServicesBlock[0].name"
        :categories="mainServicesBlock[0].categories"
      />
    </template>
    <template v-for="(block, index) in otherBlocks" :key="block.id">
      <Component
        :is="articleBlocks[block.type || block.resourcetype]"
        v-if="articleBlocks[block.type || block.resourcetype]"
        :page-header="pageHeader"
        invert-tabs
        v-bind="getBlocksProps(block, index)"
      />
    </template>
  </main>
</template>

<script setup lang="ts">
import {
  createError,
  ref,
  useArticleBlocks,
  useAsyncData,
  useHead,
  useRouter,
  useRuntimeConfig,
  useSeoMeta,
} from '#imports';
import { type ApiPageExtendedResponse, type PageBlocks } from '~/types/pages';
import './styles.scss';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';
import CBannerMainPage from '~/components/blocks/mainBannerPage/CBannerMainPage.vue';
import CBlockTabs from '~/components/blocks/tabsBlock/CBlockTabs.vue';
import CServiceCatalogCategoryBlock from '~/components/blocks/serviceCatalogCategoryBlock/CServiceCatalogCategoryBlock.vue';

const pageContent = ref<PageBlocks[]>();
const pageHeader = ref<string>();
const route = useRouter().currentRoute.value;

const { articleBlocks, getBlocksProps } = useArticleBlocks();
const { siteUrl } = useRuntimeConfig().public;

const { data, error } = await useAsyncData<ErrorResponse | ApiPageExtendedResponse>(
  'getIndexResponse',
  () => {
    return $fetch(`/api/pages/extended`, {
      method: 'GET',
      params: {
        slug: 'index',
      },
    });
  },
);

if (error.value) {
  throw createError(error.value);
}

const mainBannerBlock = ref([]);
const mainTabsBlock = ref([]);
const mainServicesBlock = ref([]);
const otherBlocks = ref([]);

if (data.value) {
  if (!isErrorResponse(data.value)) {
    pageContent.value = data.value.blocks;

    pageHeader.value = data.value.seo.h1;

    useHead({
      title: data.value.seo.title || data.value.title,
    });

    useSeoMeta({
      title: data.value.seo.title.replace(/[""'`']/g, ''),
      description: data.value.seo.description.replace(/[""'`']/g, ''),
      keywords: data.value.seo.keywords.replace(/[""'`']/g, ''),
      ogTitle: data.value.og.title.replace(/[""'`']/g, ''),
      ogDescription: data.value.og.description.replace(/[""'`']/g, ''),
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });

    pageContent.value.forEach(block => {
      if (block.type === 'BlockServiceCatalogCategory') {
        mainServicesBlock.value.push(block);
      } else if (block.type === 'BlockMainBanner') {
        mainBannerBlock.value.push(block);
      } else if (block.type === 'BlockTabs') {
        mainTabsBlock.value.push(block);
      } else {
        otherBlocks.value.push(block);
      }
    });
  }
}
</script>
